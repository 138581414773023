import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import {Container, Row, Col} from "reactstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

const ServicesPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      
      <SEO title="Services" />

      <Container className="py-5 mt-2 mt-sm-5 servicesPage">
        
        <Row className="align-items-center">

            <Col lg="6" className="d-md-none d-lg-block mb-3 mb-md-0 mb-lg-0">
              <Img alt="Working with laptops and printouts" fluid={data.services.childImageSharp.fluid} />
            </Col>

            <Col sm="12" md={{size:10, offset:1}} lg={{size:6, offset:0}}>
              <h1>Em Em Digital Services</h1>
              <h2>Website Design &amp; Development</h2>

              <ul className="list-unstyled">
                <li>
                  <FontAwesomeIcon icon={faChevronCircleRight} /> Custom Website Design
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronCircleRight} /> Content Mangement System (CMS) Development
                  <ul>
                    <li>WordPress</li>
                    <li>Joomla!</li>
                    <li>Craft CMS</li>
                  </ul>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronCircleRight} /> eCommerce Development
                  <ul>
                    <li>WooCommerce</li>
                    <li>Shopify</li>
                  </ul>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronCircleRight} /> ReactJS Development
                  <ul>
                    <li>GatsbyJS</li>
                  </ul>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronCircleRight} /> Graphic Design
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronCircleRight} /> Product Photography
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronCircleRight} /> Video Editing
                </li>
              </ul>

            </Col>

        </Row>
      </Container>

      <section className="services-logos py-5">
        <Container>
          <Row>
            <Col sm="3">
              <Img alt="WordPress logo" fluid={data.wordpress.childImageSharp.fluid} />
            </Col>
            <Col sm="3">
              <Img alt="Joomla! logo" fluid={data.joomla.childImageSharp.fluid} />
            </Col>
            <Col sm="3">
              <Img alt="HTML5 JS CSS3 logos" fluid={data.htmljscss.childImageSharp.fluid} />
            </Col>
            <Col sm="3">
              <Img alt="Git logo" fluid={data.git.childImageSharp.fluid} />
            </Col>
            <Col sm="3">
              <Img alt="GatsbyJS logo" fluid={data.gatsby.childImageSharp.fluid} />
            </Col>
            <Col sm="3">
              <Img alt="ReactJS logo" fluid={data.react.childImageSharp.fluid} />
            </Col>
            <Col sm="3">
              <Img alt="Shopify logo" fluid={data.shopify.childImageSharp.fluid} />
            </Col>
            <Col sm="3">
              <Img alt="Adobe Creative Cloud logo" fluid={data.adobe.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </section>

    </Layout>
  )
}

export default ServicesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    services: file(relativePath: { eq: "services-img.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wordpress: file(relativePath: { eq: "logos/s-_0000_logos-wordpress.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    joomla: file(relativePath: { eq: "logos/s-_0001_logos-joomla.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    htmljscss: file(relativePath: { eq: "logos/s-_0002_logos-htmljscss.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    git: file(relativePath: { eq: "logos/logos-git.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gatsby: file(relativePath: { eq: "logos/logos-gatsby.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    react: file(relativePath: { eq: "logos/logos-react.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shopify: file(relativePath: { eq: "logos/logos-shopify.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    adobe: file(relativePath: { eq: "logos/logos-adobe.png" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality:100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
